import cc from 'classcat';
import PropTypes from 'prop-types';
import { forwardRef, useContext, useEffect, useState } from 'react';

import { ACCOUNT_URLS } from '../../config';
import getUserFromJWT from '../../utils/auth/getUserFromJWT';
import AppContext from '../../config/context';
import Avatar from '../Avatar';
import Button from '../Button';
import Image from '../Image';
import Logo from '../Logo';

import css from './index.module.scss';
import { GetLinkType, LinkList } from './Links';

const { LOGOUT, PASSWORD, PROFILE } = ACCOUNT_URLS;

const authEventsSubLinks = [
  {
    title: 'All Events',
    route: '/',
  },
  {
    title: 'My Events',
    route: '/my-events',
  },
];
const AUTH_LINKS = {
  mobileLinks: [
    {
      title: 'Profile',
      href: PROFILE,
    },
    {
      title: 'Events',
      subLinks: authEventsSubLinks,
    },
    // {
    //   title: 'Settings',
    //   route: SETTINGS,
    // },
    {
      title: 'Password',
      href: PASSWORD,
    },
    // {
    //   title: 'Help & Support',
    //   route: HELP_SUPPORT,
    // },
  ],
  desktopLinks: {
    left: [
      {
        title: 'Events',
        subLinks: authEventsSubLinks,
      },
    ],
  },
};
const { mobileLinks } = AUTH_LINKS;

export const AuthMobile = forwardRef(({ mounted, open }, ref) => {
  if (!mounted) return false;

  return (
    <div className={cc([css.mobile, css.flyout, { [css.open]: open }])}>
      <div className={css.greetingRoot}>
        <Avatar />
      </div>
      <ul className={css.list} ref={ref}>
        {mobileLinks.map((mLink) => (
          <li className={css.listItem} key={`mobileLink-${mLink.title}`}>
            <GetLinkType link={mLink} flyout />
          </li>
        ))}
      </ul>
      <div className={css.logoutSection}>
        <Button href={LOGOUT} linkStyle>
          Log Out
        </Button>
      </div>
    </div>
  );
});
AuthMobile.propTypes = {
  mounted: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
};

const MyAccountLink = () => {
  const [mounted, setMounted] = useState(false);
  const defaultLink = (
    <Button href={PROFILE} linkStyle>
      My Account
    </Button>
  );

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, [mounted]);

  if (!mounted) {
    return defaultLink;
  }

  const { avatar, firstName } = getUserFromJWT();

  if (!firstName) {
    return defaultLink;
  }

  return (
    <Button href={PROFILE} linkStyle>
      <Image {...avatar} className={css.navAvatar} crop sizes={[{ size: 24, minWidth: 0 }]} />
      <span>Hi, {firstName}!</span>
    </Button>
  );
};

export const AuthDesktop = () => {
  const context = useContext(AppContext);
  const { adminLinks, markets, me, market } = context;
  const marketLinks = markets.map((mark) => ({ route: `/${mark.slug}`, title: mark.name }));
  const subLinks = marketLinks.length < 2 ? authEventsSubLinks : [...authEventsSubLinks, ...marketLinks];
  let links = [
    {
      title: 'Events',
      subLinks,
      value: market,
    },
  ];
  if (me && me.isStaff) {
    links = links.concat({
      title: 'Admin',
      subLinks: [
        {
          title: 'Events Hub',
          route: 'adminEvents',
        },
        {
          title: 'Create Event',
          route: 'adminEventNew',
          params: { step: 'settings' },
        },
        ...adminLinks,
      ],
    });
  }
  return (
    <div className={cc([css.desktop, css.header, css.desktopNav])}>
      <LinkList className={css.left} links={links} key="left-list" />
      <Logo className={css.logo} buttonLink />
      <div className={css.right}>
        <MyAccountLink />
      </div>
    </div>
  );
};

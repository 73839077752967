import cc from 'classcat';
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import scrollTop from 'events/utils/dom/scrollTop';

import Button from '../Button';
import HamburgerMenu from '../icons/HamburgerMenu';
import Logo from '../Logo';
import XLarge from '../icons/XLarge';
import css from './index.module.scss';
import { ACCOUNT_URLS, OV_HELP_URL, SITE_URL } from '../../config';
import { AuthDesktop, AuthMobile } from './Authenticated';
import { UnAuthDesktop, UnAuthMobile } from './UnAuthenticated';
import AccountIcon from '../icons/AccountIcon';

const { LOGIN, SIGN_UP } = ACCOUNT_URLS;

const HEADER_HEIGHT = 72;

class Nav extends PureComponent {
  constructor(props) {
    super(props);

    this.rootRef = createRef();
    this.flyoutRef = createRef();

    this.state = {
      mounted: false,
      open: false,
    };
  }

  componentDidMount() {
    this.body = document.querySelector('body');
    this.html = document.querySelector('html');
    this.scrollTop = 0;

    this.setState({ mounted: true });
    this.toggleFlyout();
  }

  componentWillUnmount() {
    this.onClose();
  }

  onClose = () => {
    if (!this?.flyoutRef?.current) {
      return false;
    }

    const { current: flyoutEl } = this.flyoutRef;
    flyoutEl.removeEventListener('click', this.showUpdatedRender);

    this.setState({ open: false }, () => {
      this.html.scrollTop = this.scrollTop + HEADER_HEIGHT;
    });

    return false;
  };

  onOpen = () => {
    if (!this?.flyoutRef?.current) {
      return false;
    }

    const { scrolled } = this.props;
    const offset = scrolled ? HEADER_HEIGHT : 0;
    this.scrollTop = scrollTop() - offset;

    this.setState({ open: true }, () => {
      this.body.scrollTop = this.scrollTop;
    });

    const { current: flyoutEl } = this.flyoutRef;
    flyoutEl.addEventListener('click', this.showUpdatedRender);

    return false;
  };

  // Close flyout on route changes.
  showUpdatedRender = (event) => {
    if (!this?.flyoutRef?.current) {
      return false;
    }

    const anchorHref = event.target.getAttribute('href');
    const { current: flyoutEl } = this.flyoutRef;

    if (!anchorHref || !flyoutEl.contains(event.target)) {
      return false;
    }

    this.onClose();
    return true;
  };

  toggleFlyout = () => {
    const { open } = this.state;

    if (!open) {
      this.onOpen();
      return true;
    }

    this.onClose();
    return false;
  };

  render() {
    const { mounted, open } = this.state;
    const { headerClass, loggedIn } = this.props;
    const redirect = `${LOGIN}?redirectTo=${mounted ? window.location.href : SITE_URL}`;
    const UN_AUTH_LINKS = {
      mobile: [
        {
          title: 'Sign In',
          href: redirect,
        },
        {
          title: 'Create Account',
          href: SIGN_UP,
        },
        {
          title: 'Events',
          route: '/',
        },
        {
          title: 'Help & Support',
          route: OV_HELP_URL,
        },
      ],
      desktop: [
        {
          title: ' ',
          icon: <AccountIcon />,
          subLinks: [
            {
              title: 'Sign In',
              href: redirect,
            },
            {
              title: 'Create Account',
              href: SIGN_UP,
            },
          ],
        },
      ],
    };
    const openClass = { [css.open]: open };
    /* eslint-disable prettier/prettier */
    const navs = loggedIn
      ? [
        <AuthDesktop />,
        <AuthMobile open={open} ref={this.flyoutRef} mounted={mounted} />,
      ]
      : [
        <UnAuthDesktop  links={UN_AUTH_LINKS.desktop} />,
        <UnAuthMobile  links={UN_AUTH_LINKS.mobile}open={open} ref={this.flyoutRef} mounted={mounted} />
      ];
    /* eslint-enable prettier/prettier */
    const [desktopNav, mobileNav] = navs;
    return (
      <nav className={cc([css.root, openClass])} ref={this.rootRef}>
        {open && (
          <style jsx global>
            {`
              html,
              body {
                overflow-y: hidden;
              }

              header.${headerClass} {
                position: absolute;
                width: 100%;
              }
            `}
          </style>
        )}
        <div className={css.navWrap}>
          {desktopNav}
          <div className={cc([css.mobile, css.mobileNav])}>
            <div className={css.header}>
              <Button className={cc([css.hamburgerCta, openClass])} onClick={this.toggleFlyout} linkStyle>
                <HamburgerMenu className={css.openIcon} />
                <XLarge className={css.closeIcon} />
              </Button>
              <Logo className={css.logo} buttonLink />
            </div>
          </div>
          <div className={cc([css.flyoutDrawer, openClass])}>{mobileNav}</div>
          <Button
            aria-label="Click to close the mobile nav"
            className={cc([css.mobile, css.dismissArea, openClass])}
            onClick={this.onClose}
            linkStyle
            tabIndex={open ? 0 : -1}
          >
            {' '}
          </Button>
        </div>
      </nav>
    );
  }
}

Nav.defaultProps = {
  loggedIn: false,
};

Nav.propTypes = {
  headerClass: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool,
  scrolled: PropTypes.bool.isRequired,
};

export default Nav;

import cc from 'classcat';
// import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import getUserFromJWT from '../../utils/auth/getUserFromJWT';
import Image from '../Image';
import Spinner from '../icons/Spinner';
import css from './index.module.scss';

const AvatarImage = ({ avatar, title }) => {
  if (!avatar) {
    return (
      <div>
        <div className={css.noImage} />
      </div>
    );
  }
  return (
    <div>
      <Image className={css.userImage} {...avatar} alt={title} crop sizes={[{ size: 180, minWidth: 0 }]} />
    </div>
  );
};
AvatarImage.defaultProps = {
  avatar: false,
};
AvatarImage.propTypes = {
  avatar: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
  title: PropTypes.string.isRequired,
};

const Avatar = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, [mounted]);

  if (!mounted) {
    return (
      <div className={cc([css.root, css.loading])} aria-hidden="true">
        <div className={css.noImage} />
        <div className={css.loadingFirstName} />
        <Spinner />
        <p className={css.loadingDateJoined} />
      </div>
    );
  }
  const { avatar, firstName } = getUserFromJWT();
  const imageTitle = `${firstName} Profile Avatar Image`;

  return (
    <div className={css.root}>
      <AvatarImage avatar={avatar} title={imageTitle} />
      <h2 className={css.firstName}>{firstName}</h2>
      {/* {dateJoined && (
        <p className={css.dateJoined}>
          Doing Things since&nbsp;
          {moment(dateJoined).format('MMMM YYYY')}
        </p>
      )} */}
    </div>
  );
};

export default Avatar;

import cc from 'classcat';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { Link } from 'events/routes';
import Button from '../Button';
import css from './index.module.scss';
import ChevronRight from '../icons/ChevronRight';
import ChevronDown from '../icons/ChevronDown';

const NavSimple = ({ collapsible, current, iconRight, label, links }) => {
  const [open, setOpen] = useState(false);

  const onCollapseToggle = () => {
    setOpen(!open);
  };

  const listClass = cc([
    css.list,
    { [css.withCta]: collapsible || iconRight },
    { [css.collapsible]: collapsible },
    { [css.open]: open },
  ]);

  const rootTitle = collapsible ? (
    <Button className={cc([css.collapseLabel, css.label])} onClick={onCollapseToggle} linkStyle>
      {label}
      <ChevronDown className={cc([css.chevronDown, { [css.open]: open }, css.linkIcon])} />
    </Button>
  ) : (
    <h5 className={css.label}>{label}</h5>
  );

  return (
    <nav className={css.root}>
      {label && rootTitle}
      <ul className={listClass}>
        {links.map(({ route, title, url }) => {
          const params = collapsible ? false : { slug: url };
          const linkRoute = url || route;
          const buttonClass = cc([css.buttonLink, { [css.active]: linkRoute === current }]);

          return (
            <li className={css.listItem} key={url}>
              <Link route={linkRoute} params={params}>
                <a className={buttonClass}>
                  {title}
                  {iconRight && <ChevronRight className={css.linkIcon} />}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

NavSimple.defaultProps = {
  collapsible: false,
  current: false,
  label: false,
  iconRight: false,
};

NavSimple.propTypes = {
  collapsible: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  current: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      route: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    }),
  ).isRequired,
  iconRight: PropTypes.bool,
};

export default NavSimple;

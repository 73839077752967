import PropTypes from 'prop-types';

import Button from '../Button';
import ChevronRight from '../icons/ChevronRight';
import css from './index.module.scss';
import Image from '../Image';

const NavLink = ({ buttonProps, iconRight, image, title, params, route, href }) => (
  <Button className={css.root} linkStyle {...buttonProps} href={href} route={route} params={params}>
    {image && <Image className={css.img} {...image} alt={title} />}
    {title}
    {iconRight && <ChevronRight />}
  </Button>
);

NavLink.defaultProps = {
  buttonProps: false,
  iconRight: false,
  href: false,
  image: false,
  route: false,
  params: false,
};

NavLink.propTypes = {
  buttonProps: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
  iconRight: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
  title: PropTypes.string.isRequired,
  href: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  route: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  params: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
};
export default NavLink;

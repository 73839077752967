import css from './index.module.scss';

import { ACCOUNT_URLS } from '../../config';

const links = [
  {
    href: 'https://www.outdoorvoices.com/collections/w-new-arrivals',
    children: 'New Arrivals',
  },
  { href: ACCOUNT_URLS.MY_ACCOUNT, children: 'My Account' },
  { href: 'https://www.outdoorvoices.com/pages/careers', children: 'Careers' },
  // {
  //   href: 'https://www.outdoorvoices.com/pages/press/',
  //   children: 'Press',
  // },
  // {
  //   href: APPSTORE_URL,
  //   children: 'The OV App',
  // },
  {
    href: 'https://www.outdoorvoices.com/pages/accessibility/',
    children: 'Accessibility',
  },
  {
    href: 'https://www.outdoorvoices.com/privacy-policy',
    children: 'Privacy',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  { href: 'https://www.outdoorvoices.com/terms', children: 'Terms of Use' },
  // {
  //   href: 'https://therecreationalist.com/',
  //   children: 'The Recreationalist',
  // },
  // { href: '/', children: 'Events' },
  {
    href: 'https://www.instagram.com/outdoorvoices/',
    children: 'Instagram',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  { href: 'https://www.outdoorvoices.com/pages/contact-us', children: 'Help' },
];

/* eslint-disable jsx-a11y/anchor-has-content */
const Footer = () => (
  <footer className={css.root}>
    <ul className={css.list}>
      {links.map((link) => (
        <li className={css.listItem} key={link.href}>
          <a className={css.link} {...link} />
        </li>
      ))}
    </ul>
  </footer>
);
export default Footer;

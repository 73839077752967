/* eslint-disable max-len, react/prop-types */
const Icon = ({ className = '' }) => (
  <svg className={className} height="17" viewBox="0 0 12 17" width="12">
    <title>Account</title>
    <g fill="#202020" fillRule="evenodd">
      <path d="m2.00294084 16.9992202-1.99534921.0004467c-.00343031-.8572776-.00569812-1.4397159-.00680343-1.7473147l-.0007882-.2523522c0-3.3137085 2.6862915-6 6-6 3.23839694 0 5.8775718 2.5655749 5.9958615 5.7750617l.0041385.2249383v2h-2v-1c0-2.7614237-.95391846-5-4-5s-4 2.2385763-4 5l.00092612.3467175c.00048281.1617147.00115438.3792157.00201472.6525027z" />
      <path d="m6 0c2.209139 0 4 1.790861 4 4s-1.790861 4-4 4-4-1.790861-4-4 1.790861-4 4-4zm0 2c-1.1045695 0-2 .8954305-2 2s.8954305 2 2 2 2-.8954305 2-2-.8954305-2-2-2z" />
    </g>
  </svg>
);
export default Icon;

import cc from 'classcat';
import PropTypes from 'prop-types';

import css from './index.module.scss';
import NavLink from '../NavLink';
import NavSimple from '../NavSimple';
import SelectStyled from '../SelectStyled';

export const LinkList = ({ className, links }) => (
  <ul className={cc([css.list, className])}>
    {links.length > 0 &&
      links.map((listItem) => (
        <li className={css.listItem} key={`${className}-listItem-${listItem.title}`}>
          <GetLinkType link={listItem} />
        </li>
      ))}
  </ul>
);
LinkList.defaultProps = {
  className: '',
  links: [],
};
LinkList.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape()),
};

export const GetLinkType = ({ link, flyout }) => {
  const { icon, subLinks, title, value } = link;

  // Mobile (flyout) sublinks use collapsible nav
  if (flyout && subLinks) {
    const mobileSubLinks = subLinks.map((subLink) => ({
      ...subLink,
      url: subLink.route,
    }));
    return <NavSimple label={title} links={mobileSubLinks} collapsible />;
  }

  // Desktop subLinks use SelectStyled
  if (subLinks) {
    const options = subLinks.map((subLink) => {
      const { title: subLinkTitle } = subLink;
      return {
        ...subLink,
        label: subLinkTitle,
        value: subLinkTitle,
      };
    });

    return (
      <div className={css.dropDownIcon}>
        {icon}
        <SelectStyled
          {...link}
          className={css.navSelectDropDown}
          hideLabel
          label={`Select ${title}`}
          name={title}
          onChange={() => {}}
          options={options}
          placeholder={title}
          value={value && value.name}
          withChevron={!icon}
        />
      </div>
    );
  }

  return <NavLink {...link} />;
};
GetLinkType.defaultProps = {
  flyout: false,
};
GetLinkType.propTypes = {
  flyout: PropTypes.bool,
  link: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    image: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
    route: PropTypes.string,
    subLinks: PropTypes.arrayOf(PropTypes.shape()),
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
    withChevron: PropTypes.bool,
  }).isRequired,
};

import PropTypes from 'prop-types';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import AppContext from 'events/config/context';
import css from './index.module.scss';
import Footer from '../Footer';
import Header from '../Header';
import NProgress from '../NProgress';

const Layout = ({ adminLinks, children, footer, header, loggedIn, markets, market, me }) => (
  <AppContext.Provider value={{ adminLinks, market, markets, me }}>
    <NProgress />
    <div
      dangerouslySetInnerHTML={{
        __html: '<style>.js-focus-visible :focus:not(.focus-visible) {outline: none;}</style>',
      }}
    />
    <a className={css.skip} href="#main-content">
      Skip to main content
    </a>
    <div className={css.root}>
      {header && <Header adminLinks={adminLinks} loggedIn={loggedIn} markets={markets} market={market} me={me} />}
      <main className={css.main}>{children}</main>
      {footer && <Footer />}
    </div>
    <script src="https://unpkg.com/focus-visible" />
  </AppContext.Provider>
);

Layout.defaultProps = {
  adminLinks: [],
  footer: true,
  header: true,
  loggedIn: false,
  markets: [],
  market: false,
  me: false,
};

Layout.propTypes = {
  adminLinks: PropTypes.arrayOf(PropTypes.shape()),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  footer: PropTypes.bool,
  header: PropTypes.bool,
  loggedIn: PropTypes.bool,
  market: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
  markets: PropTypes.arrayOf(PropTypes.shape()),
  me: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
};

export default Layout;

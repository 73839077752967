import cc from 'classcat';
import PropTypes from 'prop-types';
import React from 'react';

import css from './index.module.scss';

import Button from '../Button';
import LogoIcon from '../icons/Logo';

const Logo = ({ buttonLink, className }) => {
  if (buttonLink) {
    return (
      <Button className={cc([css.root, className])} route="/" linkStyle>
        <LogoIcon className={css.logo} />
      </Button>
    );
  }

  return <LogoIcon className={className} />;
};

Logo.defaultProps = {
  buttonLink: false,
  className: '',
};

Logo.propTypes = {
  buttonLink: PropTypes.bool,
  className: PropTypes.string,
};

export default Logo;

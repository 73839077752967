/* eslint-disable max-len, react/prop-types */
const Icon = ({ className = '' }) => (
  <svg className={className} width="36" height="36" viewBox="0 0 36 36">
    <title>Mobile Menu</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M11,12 L25,12 L25,14 L11,14 L11,12 Z M11,17 L25,17 L25,19 L11,19 L11,17 Z M11,22 L25,22 L25,24 L11,24 L11,22 Z"
        id="Combined-Shape"
        fill="#202020"
        className="svg-fill"
      />
    </g>
  </svg>
);
export default Icon;

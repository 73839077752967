import React from 'react';
import cc from 'classcat';
import css from './Spinner.module.scss';

/* eslint-disable react/prop-types */
const Icon = ({ className, white }) => (
  <svg className={cc([css.root, className])} viewBox="25 25 50 50">
    <title>Loading</title>
    <circle
      className={cc([css.circle, { [css.white]: white }])}
      cx="50"
      cy="50"
      r="20"
      fill="none"
      strokeWidth="1"
      strokeMiterlimit="10"
    />
  </svg>
);
export default Icon;

import { useRef, useEffect } from 'react';
/* eslint-disable consistent-return */

/*
A React hook to use with add/remove event listeners.

Example usage

  import { useState } from 'react';
  import useEventListener from '@use-it/event-listener';

  const useMouseMove = () => {
    const [coords, setCoords] = useState([0, 0]);
    useEventListener('mousemove', ({ clientX, clientY }) => {
      setCoords([clientX, clientY]);
    });
    return coords;
  };

  export default useMouseMove;
*/
const useEventListener = (eventName, handler, element = global) => {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);

  return { eventName, handler, element };
};

export default useEventListener;

import cc from 'classcat';
import PropTypes from 'prop-types';
import { useState } from 'react';

import scrollTop from 'events/utils/dom/scrollTop';
import useEventListener from 'events/utils/hooks/useEventListener';

import css from './index.module.scss';
import Nav from '../Nav';

const Header = ({ loggedIn }) => {
  const [scrolled, setScrolled] = useState(false);

  useEventListener('scroll', () => {
    const top = scrollTop();
    if (top > 0 && !scrolled) {
      setScrolled(true);
    } else if (top === 0 && scrolled) {
      setScrolled(false);
    }
  });

  return (
    <header className={cc([css.root, { [css.scrolled]: scrolled }])}>
      <div className={css.inner}>
        <Nav loggedIn={loggedIn} headerClass={css.root} scrolled={scrolled} />
      </div>
    </header>
  );
};

Header.defaultProps = {
  loggedIn: false,
};

Header.propTypes = {
  loggedIn: PropTypes.bool,
};

export default Header;

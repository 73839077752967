import cc from 'classcat';
import PropTypes from 'prop-types';
import { forwardRef, useContext } from 'react';

import AppContext from '../../config/context';
import { ACCOUNT_URLS, OV_HELP_URL, SITE_URL } from '../../config';

import AccountIcon from '../icons/AccountIcon';
import css from './index.module.scss';
import Logo from '../Logo';
import { GetLinkType, LinkList } from './Links';

const { LOGIN, SIGN_UP } = ACCOUNT_URLS;

export const UnAuthMobile = forwardRef(({ mounted, open, links }, ref) => {
  if (!mounted) return false;

  return (
    <div className={cc([css.mobile, css.flyout, { [css.open]: open }])}>
      <div className={css.greetingRoot}>
        <div className={css.defaultGreeting}>
          <div className={css.circle} />
          <h2 className={css.title}>Welcome</h2>
          <span className={css.subTitle}>Start #DoingThings</span>
        </div>
      </div>
      <ul className={css.list} ref={ref}>
        {links.map((link) => (
          <li className={css.listItem} key={`link-${link.title}`}>
            <GetLinkType link={link} flyout />
          </li>
        ))}
      </ul>
    </div>
  );
});

UnAuthMobile.propTypes = {
  mounted: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
};

export const UnAuthDesktop = ({ links }) => {
  const context = useContext(AppContext);
  const { markets, market } = context;
  const marketLinks = markets.map((mark) => ({ route: `/${mark.slug}`, title: mark.name }));
  const subLinks = [{ title: 'All Events', route: '/' }, ...marketLinks];
  const eventLinks =
    markets.length < 2 ? [{ title: 'All Events', route: '/' }] : [{ title: 'Events', subLinks, value: market }];
  return (
    <div className={cc([css.desktop, css.header, css.desktopNav])}>
      <LinkList className={css.left} links={eventLinks} key="left-list" />
      <Logo className={css.logo} buttonLink />
      <LinkList className={css.right} links={links} key="right-list" />
    </div>
  );
};
UnAuthDesktop.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
